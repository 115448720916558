





























  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Action, State } from 'vuex-class';
  import { ServerResponse } from '@/services/response.types';
  import { ContentModel, FeatureModel } from '@/store/types';
  import { PartnerProviderModel } from '@/store/partner/types';
  import { PAGE_CONTENT_TYPE } from '@/constant/Enums';

  @Component({
    name: 'AboutUs',
    components: {
      protectNow: () => import('@/components/ProtectNow.vue'),
    },
  })
  export default class AboutUs extends Vue {
    @Action('GetFeatureBy') GetFeatureBy!: () => Promise<ServerResponse>;
    @Action('GetPartner', { namespace: 'partner' }) GetPartner!: () => Promise<ServerResponse>;

    @State('features') features!: FeatureModel[];
    @State('providers', { namespace: 'partner' }) providers!: PartnerProviderModel[];
    @State('page_content') page_content!: ContentModel[];

    public get aboutContent(): ContentModel {
      return this.page_content.filter((item) => item.type === PAGE_CONTENT_TYPE.HERO)[0] ?? new ContentModel();
    }

    public get Media(): ContentModel[] {
      return this.page_content.filter((item) => item.type === PAGE_CONTENT_TYPE.MEDIA);
    }

    public created(): void {
      this.init();
    }

    private init() {
      this.GetFeatureBy()
        .then(this.GetPartner)
        .catch((res) => (res?.status?.is_valid === false ? this.init() : false));
    }

    public mounted(): void {
      // on componenet mounted
    }
  }
